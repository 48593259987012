<template>
	<div>
		<h1 class="display-3">Version 1.5.0 - May 2022</h1>
		<div class="notes ">
			<hr />
			<h2><strong>SRF Updates</strong></h2>
			<h2>
				<strong>Request description field add to SRF</strong>
			</h2>
			<video width="700" autoplay loop>
				<source src="/media/releasenotes/2022/05/srf-description.mp4" type="video/mp4" />
			</video>
			<p>
				Some SRFs have included a description in the Site Name field to ensure it shows up in the list view, but this can disrupt sorting, searching
				and automatically attaching SRFs to sites within the NOC Portal. To solve this we are introducing an additional field that will show up as a
				column in the SRF List view so engineers can quickly get an idea of the request requirements without opening the request.
			</p>
			<h2>
				<strong>SRF working status process has changed.</strong>
			</h2>
			<div class="text-center">
				<img src="/media/releasenotes/2022/05/srf-working-email.png" width="500" />
			</div>
			<p>
				There are currently 331 working SRFs with the oldest of them last updated in 2020. In an effort to have a clearer, quicker gauge of active
				SRFs, requests that don’t see changes for 72 working hours will need to be moved to the pending status. This won’t be automatic, but engineers
				assigned to the working SRF will receive an email reminding them to update SRF or move it to the pending status.
			</p>
			<h2>
				<strong>SRF is getting a reject button.</strong>
			</h2>
			<!-- <img src="/media/releasenotes/2022/05/reject_srf.gif" width="700" /> -->
			<video width="700" autoplay loop="true">
				<source src="/media/releasenotes/2022/05/reject-srf.mp4" type="video/mp4" />
			</video>
			<p>
				Because it can be used for so many different scenarios, the SRF is a very big form with very few required fields. Unfortunately that means
				sometimes requests are submitted without enough information to be actionable. Engineers can now choose to reject an SRF with a reason and this
				will send the information back to the requestor to allow them to submit a new request with the required information.
			</p>
			<h1 class="mt-10">User Account Updates:</h1>
			<h2><strong>Temporary Passwords for New Accounts</strong></h2>
			<video width="700" autoplay loop="true">
				<source src="/media/releasenotes/2022/05/copy-password.mp4" type="video/mp4" />
			</video>
			<p>
				Passwords for new users are now automatically generated. This will allow admin users to create user accounts without worrying about re-using
				passwords or leaving insecure passwords unchanged because a new user hasn't signed into their account. You can learn more about this approach
				to
				<a href="https://www.eff.org/dice">password generation here.</a>
			</p>
			<h2>
				<strong>Change Password Prompts</strong>
			</h2>
			<div class="text-center">
				<img src="/media/releasenotes/2022/05/password-update.png" width="250" />
			</div>
			<p>
				New users are now prompted to change their password the first time they login. When creating a new account admins are prompted to create a new
				password for the account, but this is meant to be temporary. Now instead of reminding the user to change their password on login they are
				automatically prompted, ensuring the account is not left with a default password and only they know their new password.
			</p>
			<h2>
				<strong>Stronger Password Requirements</strong>
			</h2>
			<p>
				<strong>Password requirements have been updated to reflect stronger security measures for VSAT Logins.</strong> As we take steps to make sure
				user’s data is secure we are updating the requirements for passwords within the VSAT Portal. This will start with new users’ passwords and
				then move through existing accounts; users may see prompts to update their password to make sure it is within the requirements. You can learn
				more about this approach to
				<a href="https://docs.microsoft.com/en-us/microsoft-365/admin/misc/password-policy-recommendations?view=o365-worldwide"
					>password security here.</a
				>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'may2022',
	data() {
		return {};
	},
};
</script>

<style scoped lang="scss">
h2 {
	margin: 25px 0;
}
.notes {
	max-width: 700px;

	p {
		font-size: 14px;
		margin: 45px 0;
		line-height: 24px;
	}
}
</style>
